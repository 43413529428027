<template>
  <div class="trademarkArchives_out clearfix" id="trademarkInfor">
    <input id="copy_content" type="text" value=""  style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>
    <div style="height: 74px"></div>
    <el-button class="btnPdf"  @click="pdf" type="primary">{{$t(`tra_detail_cn.btnPdf`)}}</el-button>
    <div :class="['wrapBox',{'active': evidence == 1}]" id="pdf">
      <div class="trademarkArchives_in" :class="{en:$i18n.locale=='en-US'}" v-if="mineInfor.appno">
        <div class="tradeMarkTop">
          <ul :class="[{active: !isCN}]">
            <li>
              <div class="trademarkArchivesTop clearfix">
                <div
                  class="imgBox"
                  style="width: 360px;height:215px;text-align: center;display: table-cell;vertical-align: middle;"
                  v-if="mineInfor.image_url"
                >
                  <img
                    :src="queryDataUrl(mineInfor.image_url)"
                    class="imghover"
                    style="height: auto;max-width: 89%;max-height: 89%;vertical-align: middle;"
                  />
                </div>
                <div
                  v-else
                  class="imgBox"
                  style="width: 210px;text-align: center;vertical-align: middle;line-height: 50px;height: 215px;overflow: hidden;"
                >
                  <span>{{mineInfor.sbmc}}</span>
                </div>
                <div style="width:72%;margin-left: 40px;position: absolute;right: 2px; bottom: 0;"></div>
              </div>
            </li>
            <li>
              <div :class="['leftLi']" >
                <div style="display: flex" class="publicD">
                  <span class="littleTittle">{{$t('personal_lang2.trademarkName')}}</span>
                  <span style="display: block!important;" class="fontD trademarkName" v-if="mineInfor.sbmc">{{mineInfor.sbmc}}</span>
                  <span class="fontD" v-else>{{$t(`tra_detail_cn.leftLi[0]`)}}</span>
                  <div class="copy" v-if="mineInfor.sbmc" style="display: flex;height: 20px">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.sbmc)">复制</el-button>
                    <img @click="baidu(mineInfor.sbmc)"  style="width: 21px;cursor: pointer;margin-left: 5px;border-radius: 50%" src="../../assets/images/query/baidu.png" title="百度">
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t('query_lang.App_Reg_No')}}</span>
                  <span class="fontD">{{mineInfor.regno}}</span>
                  <div class="copy" v-if="mineInfor.regno" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.regno)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t('query_lang.ddPriority')}}</span>
                  <span class="fontD">{{mineInfor.istotal ? mineInfor.istotal :  '否' }}</span>
                  <div v-if="mineInfor.istotal" class="copy"  style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.istotal)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t('register_lang.Type_of_trademark')}}</span>
                  <span class="fontD" v-if="mineInfor.tmtype">{{mineInfor.tmtype}}</span>
                  <div class="copy" v-if="mineInfor.tmtype" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.tmtype)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t('query_lang.ddPublication')}}</span>
                  <span class="fontD">{{mineInfor.trialnum}}</span>
                  <div class="copy" v-if="mineInfor.trialnum" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.trialnum)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t('query_lang.GazetteNoofRegistration')}}</span>
                  <span class="fontD">{{mineInfor.regnum}}</span>
                  <div class="copy" v-if="mineInfor.regnum" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.regnum)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t('query_lang.jsjmStatus')}}</span>
                  <span class="fontD">{{mineInfor.tmstatus}}</span>
                  <div class="copy" v-if="mineInfor.tmstatus" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.tmstatus)">复制</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div >
                <div :class="['leftLi']" >
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[1]`)}}</span>
                    <span class="fontD">{{mineInfor.appdate}}</span>
                    <div class="copy" v-if="mineInfor.appdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.appdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[2]`)}}</span>
                    <span class="fontD">{{mineInfor.interregdate}}</span>
                    <div class="copy" v-if="mineInfor.interregdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.interregdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[3]`)}}</span>
                    <span class="fontD">{{mineInfor.latespecifieddate}}</span>
                    <div class="copy" v-if="mineInfor.latespecifieddate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.latespecifieddate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[4]`)}}</span>
                    <span class="fontD">{{mineInfor.prioritydate}}</span>
                    <div class="copy" v-if="mineInfor.prioritydate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.prioritydate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[5]`)}}</span>
                    <span class="fontD">{{mineInfor.trialdate}}</span>
                    <div class="copy" v-if="mineInfor.trialdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.trialdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[6]`)}}</span>
                    <span class="fontD">{{mineInfor.ggdate}}</span>
                    <div class="copy" v-if="mineInfor.ggdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.ggdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_cn.leftLi[7]`)}}</span>
                    <span class="fontD">{{mineInfor.validdate}}</span>
                    <div class="copy" v-if="mineInfor.validdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.validdate)">复制</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="evidence == 0" class="zhongj">
          <div class="slide" v-if="mineInfor.tmapplicant">
            <span
              class="littleTittle"
              style="margin-right: 50px;"
            >{{$t('query_lang.Applicant_name_Chinese')}}</span>
            <span @click="guanlian(mineInfor.tmapplicant,3)" style="color: #6483ED" class="trademarkName-text">{{mineInfor.tmapplicant}}</span>
            <el-button class="copy" style="display: inline-block;margin-left: 5px;" size="mini" round @click="copy(mineInfor.tmapplicant)">复制</el-button>
            <img class="copy" @click="baidu(mineInfor.tmapplicant)"  style="width: 31px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%" src="../../assets/images/query/baidu.png" title="百度">
            <img class="copy" @click="qichacha(mineInfor.tmapplicant)" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%"  src="../../assets/images/query/qichacha.png" title="企查查">
            <!--            <img class="copy"  style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle"  src="../../assets/images/query/guanlian.png" title="关联查询">-->
          </div>
          <div class="slide" v-if="mineInfor.applicant_en">
            <span
              class="littleTittle"
              style="margin-right: 50px;"
            >{{$t('query_lang.Applicant_name_English')}}</span>
            <span class="trademarkName-text">{{mineInfor.applicant_en}}</span>
          </div>
          <div :class="['trademarkName-content','slide']" v-if="mineInfor.tmaddress">
            <span
              class="littleTittle"
              style="width: 147px;margin-right: 50px;"
            >{{$t('query_lang.Applicant_address_Chinese')}}</span>
            <span class="trademarkName-text" style="line-height: 23px;">{{mineInfor.tmaddress}}</span>
            <el-button v-if="mineInfor.tmaddress" class="copy" style="display: inline-block;margin-left: 5px;" size="mini" round @click="copy(mineInfor.tmaddress)">复制</el-button>
            <img v-if="mineInfor.tmaddress" class="copy" @click="baidu(mineInfor.tmaddress)"  style="width: 31px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%" src="../../assets/images/query/baidu.png" title="百度">
          </div>
          <div :class="['trademarkName-content','slide']" v-if="mineInfor.address_en">
            <span
              class="littleTittle"
              style="width: 147px;margin-right: 50px;"
            >{{$t('query_lang.Applicant_address_English')}}</span>
            <span class="trademarkName-text" style="line-height: 23px;">{{mineInfor.address_en}}</span>
          </div>
          <div class="slide" v-if="mineInfor.tmCoApplicant.length > 0">
            <span class="publicD">
              <span
                class="littleTittle"
                style="margin-right: 113px"
              >共有人名称</span>
              <span  class="trademarkName-text">{{mineInfor.tmCoApplicant.join(";")}}</span>
            </span>
            <el-button class="copy" style="display: inline-block;margin-left: 5px;" size="mini" round @click="copy(mineInfor.tmCoApplicant.join(';'))">复制</el-button>
            <img class="copy" @click="baidu(mineInfor.tmCoApplicant.join(';'))"  style="width: 31px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%" src="../../assets/images/query/baidu.png" title="百度">
            <img class="copy" @click="qichacha(mineInfor.tmCoApplicant.join(';'))" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%" src="../../assets/images/query/qichacha.png" title="企查查">
            <!--            <img class="copy"  style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle"  src="../../assets/images/query/guanlian.png" title="关联查询">-->
          </div>
          <div class="slide" style="margin-bottom: 10px;">
            <span class="publicD">
              <span
                class="littleTittle"
                style="margin-right: 130px"
              >{{$t('query_lang.Domestic_Representative_or_Attorney_of_Record')}}</span>
              <span @click="guanlian(mineInfor.tmagent,6)" style="color: #1F46D7" class="trademarkName-text">{{mineInfor.tmagent}}</span>
            </span>
            <el-button class="copy" style="display: inline-block;margin-left: 5px;" size="mini" round @click="copy(mineInfor.tmagent)">复制</el-button>
            <img class="copy" @click="baidu(mineInfor.tmagent)"  style="width: 31px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%" src="../../assets/images/query/baidu.png" title="百度">
            <img class="copy" @click="qichacha(mineInfor.tmagent)" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%" src="../../assets/images/query/qichacha.png" title="企查查">
            <!--            <img class="copy"  style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle"  src="../../assets/images/query/guanlian.png" title="关联查询">-->
          </div>
        </div>
        <div v-if="evidence == 0" class="classTable" style="padding-bottom: 12px;">
          <table class="classTable">
            <tr>
              <th style="width:15%;">{{$t(`tra_detail_cn.classTable[0]`)}}</th>
              <th style="width:25%;">{{$t(`tra_detail_cn.classTable[1]`)}}</th>
              <th style="width:60%;position: relative">{{$t(`tra_detail_cn.classTable[2]`)}}
                <img @click="isSimilar = !isSimilar"  style="position: absolute;right: 60px;top: 14px;cursor: pointer;width: 22px;vertical-align: middle" src="../../assets/images/query/switch_sim.png" title="切换内容" alt="切换内容">
                <img v-if="isSwtchEn&&mineInfor.dandan_en" @click="swtchEn" style="position: absolute;right: 25px;top: 12px;cursor: pointer;width: 25px;vertical-align: middle" src="../../assets/images/query/cn_en.png" title="英汉互译" alt="英汉互译">
                <img v-if="!isSwtchEn&&mineInfor.dandan_en" @click="swtchEn" style="position: absolute;right: 25px;top: 12px;cursor: pointer;width: 25px;vertical-align: middle" src="../../assets/images/query/cn_en.png" title="汉英互译" alt="汉英互译">
              </th>
            </tr>
            <tr>
              <td>{{mineInfor.intcls}}</td>
              <td style="position: relative">{{mineInfor.similargroup}} <el-button  @click="copy(mineInfor.similargroup)" size="mini" round class="copy">复制</el-button></td>
              <td style="position: relative">
<!--                {{similar_dandan_cn}}-->
                <div v-if="!isSimilar">
                  <div v-if="!isSwtchEn">
                    {{mineInfor.dandan_cn}}
<!--                    mineInfor.dandan_cn-->
                  </div>
                  <div v-else>
                    {{mineInfor.dandan_en}}
                  </div>
                </div>


                <div v-else>
                  <div v-if="!isSwtchEn">
<!--                    similar_dandan_cn.join('<br/>')-->
                    <span :style="{textDecoration: item.flag ? 'line-through' : '', color: item.flag ? '#909399' : '',display: 'block'}" v-for="(item,index) in similar_dandan_cn" :key="index">{{item.item}}</span>
                  </div>
                  <div v-else>
<!--                    similar_dandan_en.join('<br/>')-->
                    <span :style="{textDecoration: item.flag ? 'line-through' : '', color: item.flag ? '#909399' : '',display: 'block'}" v-for="(item,index) in similar_dandan_en" :key="index">{{item.item}}</span>
                  </div>
                </div>
                <span v-if="isSwtchEn || isSwtchEn" class="source">来源于百度翻译,仅供参考</span>
                <el-button v-if="!isSimilar" @click="copy(!isSwtchEn ? mineInfor.dandan_cn : mineInfor.dandan_en)" size="mini" round class="copy">复制</el-button>
                <el-button v-else @click="copy(!isSwtchEn ? similar_dandan_cn.map(item => item.item).join('') : similar_dandan_en.map(item => item.item).join(''))" size="mini" round class="copy">复制</el-button>
              </td>
            </tr>
          </table>
          <!--<div class="ckgd" @click="moreBol=!moreBol">{{moreBol?"收起":"查看更多"}}</div>-->
        </div>
        <div v-if="evidence == 0" class="tradeMarkProgre">
          <span>{{$t('query_lang.ddProcedure')}}</span>
          <table class="progressTop">
            <tr>
              <th class="jinc"></th>
              <th
              >{{$t('query_lang.dddate')}}</th>
              <th class="yewu">{{$t(`tra_detail_cn.classTable[3]`)}}</th>
              <th class="huanjie">{{$t(`tra_detail_cn.classTable[4]`)}}</th>
            </tr>
            <tr v-for="(valList,index2) in flowData" :key="index2" class="progressBottom">
              <td class="inailw">
                <img :src="require('../../assets/images/query/huilineLittle.png')" alt />
              </td>
              <td>{{valList.flowdate}}</td>
              <td>{{valList.shui}}</td>
              <td>{{valList.shuiOne}}</td>
            </tr>
          </table>
        </div>
        <div v-if="evidence == 0" class="tradeMarkProgre">
          <span>公告信息</span>
          <table class="progressTop">
            <tr>
              <th class="jinc"></th>
              <th>公告日期</th>
              <th class="yewu">公告期号</th>
              <th class="huanjie">公告内容</th>
            </tr>
            <tr v-for="(valList,index2) in trademarkAnnounce" :key="index2" class="progressBottom">
              <td class="inailw">
                <img :src="require('../../assets/images/query/huilineLittle.png')" alt />
              </td>
              <td>{{valList.trialDate}}</td>
              <td>{{valList.trialNum}}</td>
              <td @click="showTrademarkAnnounce(valList)" style="cursor: pointer; color: #2f56de">{{ valList.trialType }}</td>
            </tr>
          </table>
        </div>
        <!--商标公告展示-->
        <el-drawer
          size="580px"
          :append-to-body="true"
          :visible.sync="ifShowTrademarkAnnounce"
          custom-class="trademarkAnnounceDraw"
        >
          <el-row style="text-align: right; margin: 10px;">
<!--            <el-button @click="downloadAnnounce">下载公告</el-button>-->
          </el-row>
          <iframe :src="'https://gazette.lanternfish.cn/notice/' + nowShowTrademarkAnnounceSrc" width="100%" height="100%"></iframe>
<!--          <img-->
<!--            :src="'https://gazette.lanternfish.cn/notice/' + nowShowTrademarkAnnounceSrc"-->
<!--            style="width: 100%"-->
<!--          />-->
        </el-drawer>
        <div
          v-if="evidence == 0"
          class="leftBtn"
          @click="leftBtn"
          :disabled="firstIndex == 0"
          :class="firstIndex == 0 ? 'disabledBtn' : ''"
          :title="firstIndex == 0 ? $t(`tra_detail_cn.classTable[5]`) : ''">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          v-if="evidence == 0"
          class="rightBtn"
          @click="rightBtn"
          :disabled="firstIndex == trademarkIds.length-1"
          :class="firstIndex == trademarkIds.length-1 ? 'disabledBtn' : ''"
          :title="firstIndex == trademarkIds.length-1 ? $t(`tra_detail_cn.classTable[6]`) : ''">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div v-if="evidence == 1" class="evidence">
          <div class="left">
            <div class="left_item">
              <span v-for="(item,index) in navList" :index="index" @click="navEvent(index)" :class="[{active: index == id}]">{{item.name}}</span>
            </div>
          </div>
          <div :class="['right',{active: 0 == id},{actives: 1 == id},{activess: 2 == id}]">
            <iframe frameborder="0" width="100%" height="100%" :src="srcUrl"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="btnBack" v-if="$i18n.locale=='en-US'">
      <button @click="back">{{$t('query_lang.return_')}}</button>
    </div>

    <!--底部-->
    <!--<div class="trademarkArchivesBottom" :class="{en:$i18n.locale=='en-US'}">-->
    <!--<span class="print cur" @click="printContent()">{{$t('query_lang.Print')}}</span>&lt;!&ndash;打印&ndash;&gt;-->
    <!--&lt;!&ndash;<span class="error cur">{{$t('query_lang.Report_wrong_information')}}</span>&lt;!&ndash;错误信息反馈&ndash;&gt;&ndash;&gt;-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  // import {rootImgUrl_qiufeng} from "../../service/rootUrl";

  export default {
    data() {
      return {
        srcUrl:'',
        id: 0,
        navList:[
          {
            name: '必应',
            index: 0
          },
          {
            name: '淘宝',
            index: 1
          },
          {
            name: '头条',
            index: 2
          },
          {
            name: '百度',
            index: 3
          },
          {
            name: '微博',
            index: 4
          }
        ],
        //近似详情
        isSimilar: false,
        //中英切换
        isSwtchEn: false,
        isCN: true,
        data: {},
        list: {},
        flag: {},
        infor: {},
        mineInfor: {},
        //商标流程内容
        flowData: [],
        //商标公告内容
        trademarkAnnounce:[],
        ifShowTrademarkAnnounce:false,
        nowShowTrademarkAnnounceSrc:"",
        lang: this.$i18n.locale == "zh-CN" ? "ch" : "en",
        moreBol: false,
        firstIndex: 0,
        leftDisabled: false,
        rightDisabled: false,
        trademarkIds: [],
        trademarkCountrys: [],
        similar_dandan_cn: [],
        similar_dandan_en: [],
        //网络证据
        evidence: 0
      }
    },
    components: {},
    mounted() {
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
      this.evidence = this.infor.flag
    },
    methods: {
      //下载正在展示的商标公告图片
      downloadAnnounce(){
        window.open(this.nowShowTrademarkAnnounceSrc, '_blank')
      },
      //显示点击的商标公告图片
      showTrademarkAnnounce(item){
        this.ifShowTrademarkAnnounce = true
        this.nowShowTrademarkAnnounceSrc =  item.trialImageUrl

        // let prefix = "https://test.lanternfish.cn"
        // this.nowShowTrademarkAnnounceSrc = prefix + item.trialImageUrl
      },
      //
      navEvent(index){
        switch (index) {
          case 0: this.srcUrl = `https://cn.bing.com/search?go=搜索&q=${this.mineInfor.sbmc}&qs=ds&form=BESBTB`; this.id = index;break;
          case 1: this.srcUrl = `https://uland.taobao.com/sem/tbsearch?keyword=${this.mineInfor.sbmc}`; this.id = index;break;
          case 2: window.open(`https://so.toutiao.com/search?dvpf=pc&source=input&keyword=${this.mineInfor.sbmc}`);break;
          case 3: window.open(`https://www.baidu.com/s?wd=${this.mineInfor.sbmc}`); break;
          case 4: window.open(`https://s.weibo.com/weibo?q=${this.mineInfor.sbmc}`); break;
        }
      },
      //商标公告期号
      querymentDetail(item, appno) {
        // this.$router.push({path: '/query/announcementDetail', query: {searchText: item}})
        this.$axios.post('/common/query/getNoticeInfo', {
          trialNum: item,
          appno: appno.toString()
        }).then(({data}) => {
          if (data.length > 0) {
            if (!data[0].trialImageUrl) {
              this.$message.error('暂无商标公告数据');
              return;
            }
            window.open('https://gazette.lanternfish.cn/notice/' + data[0].trialImageUrl, '_blank')
            // this.pageIndex = data[0].pageNum
            // // this.startPage = data[0].pageNum
            // // this.endPage = data[0].endPage
            // // this.trialNum = data[0].trialNum
            // this.active = this.mulu.map(item => item.trialType).indexOf(data[0].trialType)
            // console.log(this.active, "this.active==123")
            // this.pdf()
          } else {
            this.$message.error('暂无数据');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            this.$message.error('查询失败');
            console.log(error);
          })
      },
      //公告期号页面跳转
      jump(value) {
        return;
        let routeData = this.$router.resolve({
          path: '/PQ', query: {
            value: index,
            searchText: value
          }
        });
        window.open(routeData.href, '_blank');
      },
      //关联查询
      guanlian(value, index) {
        let routeData = this.$router.resolve({
          path: '/PQ', query: {
            value: index,
            searchText: value
          }
        });
        window.open(routeData.href, '_blank');
      },
      //数组去重
      removeDuplicatedItem(arr)  {
        let obj = {};
        arr = arr.reduce((newArr, next) => {
          obj[next.similargroup] ? "" : (obj[next.similargroup] = true && newArr.push(next));
          return newArr;
        }, []);
        return arr;
      },
      //获取商品、服务详情
      iconSearch(item){
        this.$axios.post("/common/query/similargroup", {
          "appNo": item.appno,
          "intcls":item.intcls
        }).then(({data}) => {
          if(data.code == 0){
            let originArr = []
            let arr = []
            let arrs = []
            this.similar_dandan_cn = []
            this.similar_dandan_en = []
            data.data.records.map(item => {
              originArr.push({
                similargroup:item.similargroup,
                delFlag:item.delFlag
              })
              return item
            })

            arr = this.removeDuplicatedItem(originArr).map(item => item.similargroup)
            arrs = this.removeDuplicatedItem(originArr).map(item => item.delFlag)
            data.data.records.map(item => {
              if(item.similargroup) this.similar_dandan_cn[arr.indexOf(item.similargroup)] = this.similar_dandan_cn[arr.indexOf(item.similargroup)] ? this.similar_dandan_cn[arr.indexOf(item.similargroup)] + item.tmDetail + ';' : this.similar_dandan_cn[arr.indexOf(item.similargroup)] = `(${item.similargroup})` + item.tmDetail + ';'
              else this.similar_dandan_cn[arr.indexOf(item.similargroup)] = this.similar_dandan_cn[arr.indexOf(item.similargroup)] ? this.similar_dandan_cn[arr.indexOf(item.similargroup)] + item.tmDetail + ';' : this.similar_dandan_cn[arr.indexOf(item.similargroup)] = `(其它)` + item.tmDetail + ';'
              if(item.similargroup) this.similar_dandan_en[arr.indexOf(item.similargroup)] = this.similar_dandan_en[arr.indexOf(item.similargroup)] ? this.similar_dandan_en[arr.indexOf(item.similargroup)] + item.tmDetailEn + ';' : this.similar_dandan_en[arr.indexOf(item.similargroup)] = `(${item.similargroup})` + item.tmDetailEn + ';'
              else this.similar_dandan_en[arr.indexOf(item.similargroup)] = this.similar_dandan_en[arr.indexOf(item.similargroup)] ? this.similar_dandan_en[arr.indexOf(item.similargroup)] + item.tmDetailEn + ';' : this.similar_dandan_en[arr.indexOf(item.similargroup)] = `(other)` + item.tmDetailEn + ';'
              return item;
            })
            this.similar_dandan_cn = this.similar_dandan_cn.map((item,index) => {
              return {
                item: item,
                flag: arrs[index]
              }
            })
            this.similar_dandan_en = this.similar_dandan_en.map((item,index) => {
              return {
                item: item,
                flag: arrs[index]
              }
            })
          }
        }).catch((error) => {
          console.log(error)
        })
      },
      //中英文切换
      swtchEn(){
        this.isSwtchEn = !this.isSwtchEn
      },

      //百度一下
      baidu(item){
        // https://www.baidu.com/s?ie=UTF-8&wd=%E4%B8%AD%E5%9B%BD
        window.open(`https://www.baidu.com/s?ie=UTF-8&wd=${item}`);
      },

      //企查查
      qichacha(item){
        // https://www.qcc.com/web/search?key=%E5%8D%8E%E4%B8%BA
        window.open(`https://www.qcc.com/web/search?key=${item}`);
      },

      //百度一下
      copy(text){
        //获取要赋值的input的元素
        var inputElement =  document.getElementById("copy_content");
        //给input框赋值
        inputElement.value = text;
        //选中input框的内容
        inputElement.select();
        // 执行浏览器复制命令
        document.execCommand("Copy");
        //提示已复制
        this.$message('已复制');
      },
      //PDF导出功能
      pdf(){
        //pdf 打印置顶
        // document.body.scrollTop = document.documentElement.scrollTop = 0
        this.getPdf("pdf", this.mineInfor.sbmc);
      },
      queryDataUrl(data) {
        // if (data && !data.includes("http://")) return rootImgUrl_qiufeng + data;
        if (data && data.includes('data:image')) return data
        else if (data && !data.includes('http://')) return 'https://tmimage.lanternfish.cn' + data
        else return ''
      },
      back() {
        this.$router.push({
          path: '/query/fast', //查询结果
          query: {
            step: 1,
            // md:"t1_0",
          }
        })
      },
      getInforQuery(tId, country) {
        if(country != 'CN'){
          this.$router.push({
            path: "/query/otherTrademarkArchives",
            query: {
              id: tId,
              country: country
            }
          })
          return;
        }
        this.$axios.post("/common/query/searchByIds", {
          country: country,
          keywork: tId,
          lang: "zh"
        }).then((res) => {
          this.mineInfor = res.data.trademark_list[0];

          this.$axios.post("/common/query/getNoticeInfo", {
            appno:this.mineInfor.appno
          }).then(response=>{
            this.trademarkAnnounce = response.data
          })

          //处理类别
          if(this.mineInfor.tmdetail_en){
            if( this.mineInfor.country == 'CN'){
              let str = "";
              let arr = this.mineInfor.tmdetail_en.split(";");
              arr.map((item) => {
                if(item) str += item + ";&nbsp;&nbsp;&nbsp;&nbsp";
              });
              this.mineInfor.dandan = str;
            }
            else {
              let str = "";
              let arr = JSON.parse(this.mineInfor.tmdetail_en);
              arr.map((item) => {
                if(item) str += item + ";&nbsp;&nbsp;&nbsp;&nbsp";
              });
              this.mineInfor.dandan = str;
            }
          }
          if(this.mineInfor.tmdetail){
            let str = "";
            let arr = this.mineInfor.tmdetail.split(";");
            arr.map(item => {
              if (item) str += item+";";
            });
            this.mineInfor.dandan_cn = str;
          }
          if(this.mineInfor.tmCoApplicant.length > 0){
            this.mineInfor.tmCoApplicant = this.mineInfor.tmCoApplicant.map(item => item.tmApplicant);
          }
          if(this.mineInfor.tmdetail_en){
            let str = "";
            let arr = this.mineInfor.tmdetail_en.split(";");
            arr.map(item => {
              if (item) str += item + ";";
            });
            this.mineInfor.dandan_en = str;
          }
          if(this.mineInfor.tmFlowInfos.length != 0){
            this.flowData = this.mineInfor.tmFlowInfos;
            this.flowData.map(item => {
              let arr = item.flowname.replace(
                /[\-\_\,\———\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g,
                "&"
              );
              let arrLength = arr.split("&").length;
              item.shui = arr.split("&")[0];
              item.shuiOne = arr.split("&")[arrLength - 1];
            });
          }

          if (this.$route.query.toprogress == 1) {
            this.$nextTick(() => {
              $(document).scrollTop($(".tradeMarkProgre").offset().top);
            });
          }
          this.iconSearch(this.mineInfor)
          this.navEvent(0)
        })
          .catch(error => {
            console.log(error);
          });
      },
      //  打印功能
      printContent(event) {
        var div_print = document.getElementById("trademarkInfor");
        var newstr = div_print.innerHTML;
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = newstr;
        window.print();
        window.location.reload();
        document.body.innerHTML = oldstr;
        return false;
      },
      leftBtn() {
        if(this.firstIndex == 0) return
        this.getInforQuery(this.trademarkIds[this.firstIndex - 1], this.trademarkCountrys[this.firstIndex - 1])
        this.firstIndex--;
      },
      rightBtn() {
        if(this.firstIndex == this.trademarkIds.length-1) return
        this.getInforQuery(this.trademarkIds[this.firstIndex + 1], this.trademarkCountrys[this.firstIndex + 1])
        this.firstIndex++;
        this.leftDisabled = false;
      }
    },
    computed: {},
    filters: {},
    created() {
      this.infor = this.$route.query;
      this.trademarkIds = JSON.parse(localStorage.getItem('douxing'))
      this.trademarkCountrys = JSON.parse(localStorage.getItem('douguo'))
      this.firstIndex = this.trademarkIds.indexOf(Number(this.infor.id))
      //打开多个窗口页面刷新不更新
      this.getInforQuery(this.infor.id, this.infor.country);
    }
  };
</script>

<style lang="less" scoped>
  .evidence {
    display: flex;
    height: 1000px;

    .left {
      width: 128px;
      height: 1000px;
      background: #F2F8FE;
      display: flex;
      justify-content: center;

      .left_item {
        display: flex;
        flex-direction: column;

        span {
          margin-top: 54px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &.active {
            width: 80px;
            height: 42px;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
          }
        }
      }
    }

    .right {
      width: calc(100% - 128px);
      height: 1000px;
      overflow: hidden;
      &.active{
        iframe {
          width: 1200px;
          /* 平移缩放，比例为外层盒子大小scale（想要呈现的大小与原网页大小做比 */
          /* 分别设置scale的宽高缩放比例即为等比缩放
          如按照原比例那么只设置 */
          transform-origin: top left;
          transform: scale(0.84, 1)
        }
      }
      &.actives{
        iframe {
          width: 1455px;
          /* 平移缩放，比例为外层盒子大小scale（想要呈现的大小与原网页大小做比 */
          /* 分别设置scale的宽高缩放比例即为等比缩放
          如按照原比例那么只设置 */
          transform-origin: top left;
          transform: scale(0.7, 1)
        }
      }
      &.activess{
        iframe {
          width: 1200px;
          /* 平移缩放，比例为外层盒子大小scale（想要呈现的大小与原网页大小做比 */
          /* 分别设置scale的宽高缩放比例即为等比缩放
          如按照原比例那么只设置 */
          transform-origin: top left;
          transform: scale(0.83, 1)
        }
      }
    }
  }
  .state {
    height: 100px !important;
    line-height: 100px !important;
  }
  .service {
    height: 70px !important;
    line-height: 70px !important;
  }
  .cur {
    cursor: pointer;
  }
  #trademarkInfor {
    .btnPdf{
      position: fixed;
      top: 106px;
      right: 1%;
    }

    @media screen and (max-width: 1600px) {
    }

    @media screen and (max-width: 1400px) {
    }


    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding-top: 16px;
    .wrapBox {
      width: 1200px;
      margin: 16px auto 200px;
      position: relative;
      &.active{
        margin: 16px auto 0px!important;
        .trademarkArchives_in{
          padding-bottom: 0!important;
        }
      }
    }
    .trademarkArchives_in {
      background-color: #fff;
      border-radius: 3px;
      padding: 46px 48px 40px;
      /*box-shadow: 0px 0px 14px 11px rgba(239,239,239,0.8);*/
      &.en {
        .trademarkArchivesTop {
          .leibie {
            /*width: auto;*/
            min-width: 72%;
          }
        }
        .trademarkArchivesMiddle {
          & > div {
            .littleTittle {
              width: auto;
              min-width: 237px;
            }
          }
        }
      }
      /*滚动条样式*/
      /*https://www.cnblogs.com/yclblog/p/6806496.html*/
      .test-1::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
      }
      .test-1::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px #eb5656;
        background: #eb5656;
      }
      .test-1::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px #e6e6e6;
        border-radius: 10px;
        background: #e6e6e6;
      }
      .trademarkArchivesTop {
        // overflow: hidden;
        position: relative;
        .leibie {
          /*margin-left: 40px;*/
          /*float: left;*/
          width: 72%;
          position: absolute;
          left: 300px;
          top: 15px;
          .leibieTitle {
            font-size: 14px;
            color: #222222;
          }
          .jutilei {
            overflow-x: hidden;
            overflow-y: auto;
            height: 200px;
            margin-top: 20px;
            border: none;
          }
        }
        .imghover {
          transition: all 0.6s;
          cursor: pointer;
        }
        .imghover:hover {
          transform: scale(1.65);
        }
        .imgBox {
          span {
            font-size: 35px;
            display: inline-block;
            max-width: 100%;
            max-height: 96%;
            margin: auto;
            text-align: center;
            vertical-align: middle;
            font-weight: bold;
            word-break: break-word;
          }
          .TextSize{
            font-size: 22px;
            line-height: 25px;
          }
        }
      }
      .trademarkName-text {
        /*width: calc(100% - 245px);*/
        /*display: inline-block;*/
        line-height: 20px;
      }
      .trademarkArchivesMiddle {
        text-align: left;
        & > div {
          height: 50px;
          border-bottom: 1px solid #e2e2e2;
          line-height: 50px;
          padding-left: 2px;
          .littleTittle {
            display: inline-block;
            width: 147px;
            color: #555;
            font-weight: bold;
          }
          .publicD {
            display: inline-block;
            width: 55%;
          }
          .pubdd {
            width: 43%;
          }
        }
        .trademarkName-content {
          height: auto;
          min-height: 50px;
          & > * {
            vertical-align: middle;
          }
          .littleTittle {
            width: 237px;
          }
        }
      }
      .zhongj {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
        div {
          height: 50px;
          line-height: 50px;
          border-top: 1px solid #dddddd;
          .littleTittle {
            font-weight: bold;
            color: #333;
          }
          .trademarkName-text {
            color: #666;
          }
        }
        .slide{
          cursor: pointer;
          .copy{
            visibility: hidden;
          }
          &:hover{
            .copy{
              visibility: visible;
            }
          }
        }
      }
      .classTable {
        width: 100%;
        min-height: 100px;
        tr {
          text-align: center;
          th {
            height: 50px;
            line-height: 50px;
            background: #f4f4f4;
            border: 1px solid #dedede;
            border-bottom: none;
            border-right: none;
          }
          th:nth-of-type(3) {
            border-right: 1px solid #dedede;
          }
          td {
            height: 50px;
            border-right: 1px solid #dedede;
            border-bottom: 1px solid #dedede;
          }
          td:nth-of-type(1) {
            border-left: 1px solid #dedede;
            width: 8%;
            vertical-align: middle;
          }
          td:nth-of-type(2) {
            width: 30%;
            vertical-align: middle;
            word-break: break-word;
            padding: 12px 20px 15px 20px;
            line-height: 23px;
          }
          td:nth-of-type(3) {
            width: 62%;
            p {
              display: inline-block;
              padding: 12px 20px 25px 20px;
              line-height: 23px;
              text-align: justify;
              word-break: break-word;
            }
          }
          .copy{
            visibility: hidden;
          }
          &:hover{
            .copy{
              visibility: visible;
            }
          }
        }
        .ckgd {
          width: 100%;
          height: 50px;
          border: 1px solid #dedede;
          border-top: none;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }

        .copy {
          position: absolute;
          right: 3px;
          bottom: 3px;
          padding: 5px 15px !important;
        }

        .source {
          color: #666;
          font-size: 12px;
          position: absolute;
          right: 50px;
          bottom: 3px;
          padding: 5px 15px !important;
        }
      }
      .tradeMarkProgre {
        border: none;
        margin-top: 26px;
        span {
          font-weight: bold;
          padding-left: 2px;
          color: #555;
        }
        .progressTop {
          margin-top: 16px;
          width: 100%;
          border-radius: 3px;
          font-size: 14px;
          color: #333;
          span {
            display: inline-block;
          }
          tr:nth-of-type(1) {
            height: 50px;
            background: #f2f2f2;
            th:nth-of-type(1) {
              width: 10%;
            }
            th:nth-of-type(2) {
              width: 20%;
            }
            th:nth-of-type(3) {
              width: 30%;
            }
            th:nth-of-type(4) {
              width: 40%;
            }
          }
        }
        .progressBottom:nth-child(2) {
          margin-top: 30px;
          .inailw {
            background: url("../../assets/images/query/redjiaotpu.png") no-repeat 20px;
            position: relative;
            img {
              position: absolute;
              left: 32px;
              top: 42px;
            }
          }
        }
        .progressBottom {
          font-size: 14px;
          color: #333;
          width: 100%;
          height: 60px;
          span {
            display: inline-block;
          }
          .inailw {
            background: url("../../assets/images/query/huijiantou.png") no-repeat 20px;
            height: 26px;
            width: 12%;
            position: relative;
            img {
              position: absolute;
              left: 32px;
              top: 42px;
            }
          }
          span:nth-child(2) {
            width: 20%;
          }
          span:nth-child(3) {
            width: 30%;
          }
          span:nth-child(4) {
            width: 30%;
          }
        }
        .progressBottom:last-child {
          .inailw {
            img {
              display: none;
            }
          }
        }
      }
      .tradeMarkTop {
        min-height: 325px;
        ul {
          li {
            width: 300px;
            height: 282px;
            background: #fff;
            display: inline-block;
            margin-right: 20px;
            vertical-align: top;
            .leftLi {
              padding-left: 30px;
              padding-top: 20px;
              .publicD {
                /*display: flex;*/
                /*align-items: center;*/
                margin-bottom: 18px;
                /deep/.el-button--mini, /deep/.el-button--mini.is-round {
                  padding: 3px 8px;
                }
                .littleTittle {
                  display: inline-block!important;
                  width: 130px;
                  font-weight: bold;
                  color: #000;
                  vertical-align: top;
                }
                .fontD {
                  display: inline-flex!important;
                  font-size: 14px;
                  color: #666;
                  width: auto;
                  /*display: flex;*/
                  align-items: center;
                }
                .copy {
                  display: inline-flex!important;
                  margin-left: 5px;
                  visibility: hidden;
                }

                &:hover{
                  .copy {
                    visibility: visible;
                  }
                }
              }
              .trademarkName{
                display: block!important;
                max-width: 150px!important;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }
            .leftLiOne {
              padding-left: 30px;
              padding-top: 20px;
              padding-right: 50px;
              span {
                display: inline-block;
              }
              .publicD {
                display: flex;
                align-items: center;
                margin-bottom: 18px;
                .littleTittle {
                  display: inline-block;
                  width: 130px;
                  font-weight: bold;
                  color: #000;
                }
                .fontD {
                  font-size: 14px;
                  color: #666;
                }
              }
            }
          }
          li:nth-of-type(1) {
            width: 215px;
            height: 215px;
            background: #fff;
            border: 1px solid #f2f2f2;
            margin-top: 20px;
          }
          li:nth-of-type(2) {
            width: 400px;
            border-right: 1px solid #dddddd;
          }
          li:nth-of-type(3) {
            margin-right: 0;
            width: 364px;
            background: #fff;
          }
          &.active{
            .publicD {
              display: flex;
              /*justify-content: space-between;*/

              .littleTittle {
                font-size: 16px;
                white-space: nowrap;
                width: auto;
                margin-right: 20px;
              }
              .fontD {
                font-size: 14px;
                color: #666;
                display: inline-block;
                /*width: 50%;*/
                margin-right: 20px;
                display: flex;
                white-space: nowrap;
                justify-content: space-between;
              }
            }

            li:nth-of-type(2) {
              width: 430px;
              border-right: 1px solid #dddddd;
            }

            .leftLi .publicD .littleTittle{
              width: auto;
            }
          }
        }
      }
    }
    .btnBack {
      button {
        width: 114px;
        height: 40px;
        background: #2f56de;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        margin-top: 25px;
        float: right;
        margin-right: 60px;
      }
      button:hover {
        background: #d73e3f;
      }
    }
    .trademarkArchivesBottom {
      width: 280px;
      height: 50px;
      margin-top: 35px;
      /*margin-bottom: 110px;*/
      position: absolute;
      right: 0;
      &.en {
        width: auto;
        span {
          width: auto;
          min-width: 114px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      span {
        width: 114px;
        height: 40px;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        border-radius: 3px;
      }
      .print {
        background-color: #2f56de;
        margin-right: 42px;
      }
      .error {
        background-color: #434454;
      }
    }
  }
  .leftBtn {
    width: 30px;
    height: 60px;
    background-color: #6483ED;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    position: absolute;
    left: 5px;
    top: 400px;
    color: #fff;
    cursor: pointer;
    &:hover{
      -moz-box-shadow:0px 0px 4px #333333; -webkit-box-shadow:0px 0px 4px #333333; box-shadow:0px 0px 4px #333333;
    }
  }
  .rightBtn {
    width: 30px;
    height: 60px;
    background-color: #6483ED;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    position: absolute;
    right: 5px;
    top: 400px;
    color: #fff;
    cursor: pointer;
    &:hover{
      -moz-box-shadow:0px 0px 4px #333333; -webkit-box-shadow:0px 0px 4px #333333; box-shadow:0px 0px 4px #333333;
    }
  }
  .disabledBtn {
    cursor: not-allowed;
    background-color: #ccc;
  }

</style>
<style scoped lang="less">
  @media screen and (max-width: 1400px) {
    #trademarkInfor {
      .wrapBox {
        width: 1100px !important;
        .trademarkArchives_in {
          .trademarkArchivesTop {
            .leibie {
              width: 70%;
            }
          }
        }
      }
    }

    #trademarkInfor
    .trademarkArchives_in
    .tradeMarkProgre
    .progressTop
    tr:nth-of-type(1)
    th:nth-of-type(2) {
      text-indent: -128px !important;
    }
  }

  @media screen and (max-height: 720px) {
    #trademarkInfor {
      .wrapBox {
        width: 1000px !important;

        .trademarkArchives_in {
          .trademarkArchivesTop {
            .leibie {
              width: 70%;
            }
          }
        }
      }
    }

    #trademarkInfor
    .trademarkArchives_in
    .tradeMarkProgre
    .progressTop
    tr:nth-of-type(1)
    th:nth-of-type(2) {
      text-indent: -128px !important;
    }
  }
</style>
<style lang="scss">
.trademarkAnnounceDraw #el-drawer__title{
  display:none;
}
</style>
